import styleClasses from "./MyCheckbox.module.css";

export default function MyCheckbox({ isChecked, setIsChecked, label, className }) {
    const classList = [styleClasses.checkbox]

    if (isChecked) {
        classList.push(styleClasses.active)
    }
    
    return (
    <label className={className}>
        <input
        type="checkbox"
        onChange={() => {
            setIsChecked(!isChecked);
        }}
        />
        <span
            className={classList.join(' ')}
            aria-hidden="true"
        />
        { label }
    </label>
    );
    }