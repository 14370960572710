import { useEffect, useState } from 'react';
import AccountsListItem from '../AccountsListItem/AccountsListItem';
import slyleClasses from './AccountsList.module.css'
import MyButton from '../UI/MyButton/MyButton';
import MyModalWindow from '../UI/MyModalWindow/MyModalWindow';
import CreateAccountWindow from '../CreateAccountWindow/CreateAccountWindow';
import API from '../../API/API';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

function AccountsList(props) {
    const { setIsLoading, isLoading } = props
    const [ accounts, setAccounts ] = useState([])
    const [ isCreateAccountModalVisible, setIsCreateAccountModalVisible ] = useState(false)

    useEffect(() => {
        refreshAccounts()
    }, [])

    
    function refreshAccounts() {
        API.getAccounts()
            .then((accounts) => {
                setAccounts(accounts)
                setIsLoading(false)
            })
            .catch(console.error)
    }
    
    function onDelete(accountId) {
        API.deleteAccount(accountId)
            .then(refreshAccounts)
            .catch(console.error)
    }

    if (isLoading){
        return <LoadingSpinner />
    }
    return (
        <div className={slyleClasses.container}>
            <div className={slyleClasses.list}>
                {
                    accounts.map(u => (
                        <AccountsListItem
                            key={u.id}
                            account={u}
                            onDelete={onDelete}
                        />
                    ))
                }
            </div>
            <MyButton onClick={() => setIsCreateAccountModalVisible(true)}>Добавить</MyButton>
            <MyModalWindow
                visible={isCreateAccountModalVisible}
                title='Добавление имени аккаунта'
                onClose={() => setIsCreateAccountModalVisible(false)}
            >
                <CreateAccountWindow
                    refreshAccounts={refreshAccounts}
                    closeWindow={() => setIsCreateAccountModalVisible(false)}
                />
            </MyModalWindow>
        </div>
    );
}

export default AccountsList;
