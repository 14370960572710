import axios from "axios"

const authHeader = window.Telegram.WebApp.initData
axios.defaults.headers.common['Authorization'] = window.btoa(authHeader)

class Axios {
    static async get(...args) {
        try {
            const resp = await axios.get(...args)
            if (resp.status === 401) {
                window.location = '/error'
                return
            }
            return resp
        } catch (e) {
            window.location = '/error'
        }
    }

    static async post(...args) {
        return axios.post(...args)
    }

    static async delete(...args) {
        return axios.delete(...args)
    }
}

export default Axios;