import React from "react";
import UsernamesList from "../components/UsernamesList/UsernamesList";
import styleClasses from './Page.module.css'

export const HomePage = () => {
    return (
        <div className={styleClasses.page}>
            <UsernamesList />
        </div>
    )
}