import { AccountStatus } from "../constants/AccountStatus";
import { UsernameStatus } from "../constants/UsernameStatus";

export function getLabelsByUsernameStatus(status) {
    switch (status) {
        case UsernameStatus.ADDED:
            return {
                text: 'Добавлен',
                color: '#1CAC78'
            }
        case UsernameStatus.BUSY:
            return {
                text: 'Занят',
                color: '#9B0D00'
            }
        case UsernameStatus.FREE:
            return {
                text: 'Свободен',
                color: '#1CAC78'
            }
        case UsernameStatus.INTERCEPTED:
            return {
                text: 'Перехвачен',
                color: '#1CAC78'
            }
        default:
            return {
                text: 'Неопределенный статус',
                color: '#9B0D00'
            }
    }
}

export function getStatusLabelByAccount(account) {
    switch (account.status) {
        case AccountStatus.ADDED:
            return {
                text: 'Добавлен, не в работе',
                color: '#9B0D00'
            }
        case AccountStatus.READY:
            if (new Date(account.waitFor) > new Date() && new Date(account.waitForV2) > new Date()) {
                return {
                    text: 'В блоке',
                    color: 'rgb(232 130 0)'
                }
            }
            if (new Date(account.waitFor) > new Date() || new Date(account.waitForV2) > new Date()) {
                return {
                    text: 'В частичном блоке',
                    color: 'rgb(232 130 0)'
                }
            }
            return {
                text: 'В работе',
                color: '#1CAC78'
            }
        case AccountStatus.BANNED:
            return {
                text: 'Забанен',
                color: '#9B0D00'
            }
        case AccountStatus.BLOCKED:
            return {
                text: 'Временно заблокирован',
                color: '#1CAC78'
            }
        case AccountStatus.ERROR:
            return {
                text: 'Ошибка',
                color: '#9B0D00'
            }
        default:
            return {
                text: 'Неопределенный статус',
                color: '#9B0D00'
            }
    }
}