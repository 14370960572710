import React, { useContext } from "react";
import NotificationContext from "../../context/NotificationContext";
import Notification from "../Notification/Notification";

const Footer = () => {
  const { notifications } = useContext(NotificationContext);

  return (
    <>
      {notifications.map((notification, i) => {
        return (
          <Notification
            key={i}
            text={notification.text}
            status={notification.status}
            onFinish={notification.onFinish}
            actionText={notification.actionText}
            action={notification.action}
          />
        );
      })}
    </>
  );
};

export default Footer;