import React, { useState } from "react";
import styleClasses from './Page.module.css'
import AccountsList from "../components/AccountsList/AccountsList";

export const AccountPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    return (
        <div className={styleClasses.page}>
            <AccountsList
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </div>
    )
}