import { useState } from 'react';
import MyButton from '../UI/MyButton/MyButton';
import MyInput from '../UI/MyInput/MyInput';
import slyleClasses from './CreateUsernameWindow.module.css'

function CreateUsernameWindow({ onSave }) {
    const [username, setUsername] = useState('')

    function onSubmit(e) {
        e.preventDefault()
        if (!username) {
            window.alert('Имя пользователя не может быть пустым')
            return
        }
        onSave(username);
    }

    return (
        <div>
            <form onSubmit={onSubmit} className={slyleClasses.form}>
                <MyInput
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Имя пользователя'
                />
                <MyButton>Сохранить</MyButton>
            </form>
        </div>
    );
}

export default CreateUsernameWindow;
