import { useContext, useEffect, useState } from 'react';
import slyleClasses from './SettingsList.module.css'
import API from '../../API/API';
import SettingsListItem from '../SettingsListItem/SettingsListItem';
import { getParamName } from '../../utils/params';
import MyButton from '../UI/MyButton/MyButton';
import NotificationContext from '../../context/NotificationContext';

function SettingsList(props) {
    const { addNotification } = useContext(NotificationContext);

    const [ params, setParams ] = useState([
        {
            typeId: 1,
            value: ''
        },
    ])

    useEffect(() => {
        refreshSettings()
    }, [])

    
    function refreshSettings() {
        API.getParams()
            .then(setParams)
            .catch(console.error)
    }
    
    function onSubmit(e) {
        e.preventDefault()
        API.saveParams(params)
            .catch(console.error)
            .then(() => {
                addNotification({
                    text: "Успешно сохранено!",
                    status: 'success',
                  });
            })
    }

    function onChange(typeId, value) {
        params.find((p) => p.typeId === typeId).value = value
        setParams([...params])
    }

    return (
        <div>
            <form onSubmit={onSubmit} className={slyleClasses.container}>
            {
                params.map((param) => (
                    <SettingsListItem
                        name={getParamName(param.typeId)}
                        value={param.value}
                        onChange={onChange}
                        typeId={param.typeId}
                        key={param.typeId}
                    />
                ))
            }
            <MyButton>Сохранить</MyButton>
            </form>
        </div>
    );
}

export default SettingsList;
