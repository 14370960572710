import { useEffect } from "react"
import styleClasses from './MyModalWindow.module.css'

export default function MyModalWindow({
    visible = false,
    title = '',
    children = '',
    footer = '',
    onClose,
  }) {
    const onKeydown = ({ key }) => {
      switch (key) {
        case 'Escape':
          onClose()
          break
        default:
            return
      }
    }
  
    useEffect(() => {
      document.addEventListener('keydown', onKeydown)
      return () => document.removeEventListener('keydown', onKeydown)
    })
  
    if (!visible)return null
  
    return (
      <div className={ styleClasses.modal } onClick={onClose}>
        <div className={ styleClasses.modalDialog } onClick={e => e.stopPropagation()}>
          <div className={ styleClasses.modalBody }>
            <div className={ styleClasses.modalContent }>{children}</div>
          </div>
          {footer && <div className={ styleClasses.modal-footer }>{footer}</div>}
        </div>
      </div>
    )
  }