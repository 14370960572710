import React from "react";
import styleClasses from './Page.module.css'
import SettingsList from "../components/SettingsList/SettingsList";

export const SettingsPage = () => {
    return (
        <div className={styleClasses.page}>
            <SettingsList />
        </div>
    )
}