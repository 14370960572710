import React from "react";
import styleClasses from './LoadingSpinner.module.css'

export default function LoadingSpinner() {
  return (
    <div className={styleClasses.spinnerContainer}>
      <div className={styleClasses.loadingSpinner}>
      </div>
    </div>
  );
}