import { formatDateTime } from '../../utils';
import { getLabelsByUsernameStatus } from '../../utils/labels';
import slyleClasses from './UsernamesListItem.module.css'

function UsernamesListItem(props) {
    const { username, onDelete } = props
    const statusLabel = getLabelsByUsernameStatus(username.status)

    function onDeleteUsername() {
        const isConfirm = window.confirm(`Вы уверены что хотите удалить ${ username.name }`)
        if (!isConfirm) {
            return
        }
        onDelete(username.id)
    }

    return (
        <div className={slyleClasses.item}>
            <div
                className={[slyleClasses.cell, slyleClasses.name].join(' ')}
            >
                Имя пользователя: {username.name}
            </div>
            <div
                style={ { color: statusLabel.color } }
                className={[slyleClasses.cell, slyleClasses.status].join(' ')}
            >
                Статус: {statusLabel.text}
            </div>
            <div className={[slyleClasses.cell, slyleClasses.date].join(' ')}>
                {
                    username.lastCheckedAt
                    ? ['Последняя проверка', formatDateTime(username.lastCheckedAt.toString())].join(': ')
                    : ''
                }
            </div>
            <div className={[slyleClasses.cell, slyleClasses.date].join(' ')}>
                {
                    username.accountPhoneNumber
                    ? ['Номер аккаунта', username.accountPhoneNumber].join(': ')
                    : ''
                }
            </div>
            <div className={slyleClasses.buttons}>
                <img
                    className={slyleClasses.button}
                    src='delete.png'
                    alt='delete'
                    onClick={onDeleteUsername}
                />
            </div>

        </div>
    );
}

export default UsernamesListItem;
