import { formatDateTime } from '../../utils';
import { getStatusLabelByAccount } from '../../utils/labels';
import slyleClasses from './AccountsListItem.module.css'

function AccountsListItem(props) {
    const { account, onDelete } = props
    const statusLabel = getStatusLabelByAccount(account)

    function onDeleteAccount() {
        const isConfirm = window.confirm(`Вы уверены что хотите удалить ${ account.phoneNumber }`)
        if (!isConfirm) {
            return
        }
        onDelete(account.id)
    }

    return (
        <div className={slyleClasses.item}>
            <div
                className={[slyleClasses.cell, slyleClasses.name].join(' ')}
            >
                Номер: {account.phoneNumber}
            </div>
            <div
                style={ { color: statusLabel.color } }
                className={[slyleClasses.cell, slyleClasses.status].join(' ')}
            >
                Статус: {statusLabel.text}
            </div>
            <div className={[slyleClasses.cell, slyleClasses.text].join(' ')}>
                Кол-во перехваченных имен: {account.countInterceptedAccounts}
            </div>
            {
                account.isDirtyAccount
                ? <div className={[slyleClasses.cell, slyleClasses.checkbox].join(' ')}>
                    Не сохраняются имена пользователя
                </div>
                : null
            }
            {
                account.lastUsedAt
                ? <div className={[slyleClasses.cell, slyleClasses.text].join(' ')}>

                    Последнее использование: {formatDateTime(account.lastUsedAt)}

                </div>
                : null
            }
            {
                account.waitFor && new Date(account.waitFor) > new Date()
                ? <div className={[slyleClasses.cell, slyleClasses.text].join(' ')}>
                
                    Дата разблокировки 1 способа: {formatDateTime(account.waitFor)}
                </div>
                : null
            }
            {
                account.waitForV2 && new Date(account.waitForV2) > new Date()
                ? <div className={[slyleClasses.cell, slyleClasses.text].join(' ')}>
                
                    Дата разблокировки 2 способа: {formatDateTime(account.waitForV2)}
                </div>
                : null
            }
            <div className={slyleClasses.buttons}>
                <img
                    className={slyleClasses.button}
                    src='delete.png'
                    alt='delete'
                    onClick={onDeleteAccount}
                />
            </div>

        </div>
    );
}

export default AccountsListItem;
