import slyleClasses from './MyButton.module.css'

function MyButton({ className, children, ...props }) {
    return (
        <button { ...props } className={[slyleClasses.button, className].join(' ')}>
            { children }
        </button>
    );
}

export default MyButton;
