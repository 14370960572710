import PageRouter from './components/PageRouter/PageRouter';
import Footer from './components/Footer/Footer';
import NotificationContext from './context/NotificationContext';
import useNotifications from './hooks/useNotification';
import './styles.css'

function App() {
  const { notifications, addNotification } = useNotifications();

  return (
    <div className="App">
      <NotificationContext.Provider
        value={{
          notifications,
          addNotification,
        }}
      >
        <PageRouter />
        <Footer />
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
