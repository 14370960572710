import { useEffect, useState } from 'react';
import UsernamesListItem from '../UsernamesListItem/UsernamesListItem';
import slyleClasses from './UsernamesList.module.css'
import MyButton from '../UI/MyButton/MyButton';
import MyModalWindow from '../UI/MyModalWindow/MyModalWindow';
import CreateUsernameWindow from '../CreateUsernameWindow/CreateUsernameWindow';
import API from '../../API/API';

function UsernamesList(props) {
    const [ usernames, setUsernames ] = useState([])

    useEffect(() => {
        refreshUsernames()
    }, [])

    const [ isCreateUsernameModalVisible, setIsCreateUsernameModalVisible ] = useState(false)
    
    function refreshUsernames() {
        API.getUsernames()
            .then(setUsernames)
            .catch(console.error)
    }
    
    function onSaveUsername(username) {
        setIsCreateUsernameModalVisible(false)
        API.createUsername(username)
            .then(refreshUsernames)
            .catch(console.error)
    }

    function onDelete(usernameId) {
        API.deleteUsername(usernameId)
            .then(refreshUsernames)
            .catch(console.error)
    }

    return (
        <div className={slyleClasses.container}>
            <div className={slyleClasses.list}>
                {
                    usernames.map(u => (
                        <UsernamesListItem
                            key={u.id}
                            username={u}
                            onDelete={onDelete}
                        />
                    ))
                }
            </div>
            <MyButton onClick={() => setIsCreateUsernameModalVisible(true)}>Добавить</MyButton>
            <MyModalWindow
                visible={isCreateUsernameModalVisible}
                onClose={() => setIsCreateUsernameModalVisible(false)}
            >
                <CreateUsernameWindow
                    onSave={onSaveUsername}
                />
            </MyModalWindow>
        </div>
    );
}

export default UsernamesList;
