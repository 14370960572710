import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "../../routes";

function PageRouter() {
  return (
    <div>
        <BrowserRouter>
            {/* <Menu /> */}
            <Routes>
                {
                    routes.map((r) => (<Route path={r.path} key={r.path} exact={r.exact} element={<r.component />}/>))
                }
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default PageRouter;
