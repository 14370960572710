import MyInput from '../UI/MyInput/MyInput';
import slyleClasses from './SettingsListItem.module.css'

function SettingsListItem({ name, value, typeId, onChange }) {

    return (
        <div className={slyleClasses.item}>
            <div>{name}</div>
            <MyInput
                placeholder={name}
                onChange={(e) => onChange(typeId, e.target.value)}
                value={value}
            />
        </div>
    );
}

export default SettingsListItem;
