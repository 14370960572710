import './Notification.css'

const Notification = ({ text, status, onFinish, actionText, action }) => {
    return (
      <div
        className={`notification notification-${status}`}
        role="status"
        aria-live="polite"
        onAnimationEnd={onFinish}
      >
        <span>{text}</span>
  
        <div className="notification-buttons">
          {actionText !== null && (
            <button
              type="button"
              className="notification-button notification-action"
              onClick={action}
            >
              {actionText}
            </button>
          )}
  
          <button
            type="button"
            className="notification-button"
            onClick={onFinish}
            aria-label="Close notification"
            title="Close"
          >
            &#x2715;
          </button>
        </div>
      </div>
    );
  };
  
  export default Notification;
  