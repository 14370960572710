import axios from './axios'

export default class API {
    static BACKEND_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : '/api'
  
    static async getUsernames() {
        const endpoint = '/username'
        const url = this._getURL(endpoint)

        try {
            const resp = await axios.get(url)
            return resp.data.usernames.map((username) => ({
                id: username.id,
                name: username.name,
                lastCheckedAt: username.last_checked_at,
                status: username.status,
                accountPhoneNumber: username.account_phone_number,
            }))
        } catch(e) {
            console.error(e)
        }
    }

    static async createUsername(name) {
        const endpoint = '/username'
        const url = this._getURL(endpoint)

        try {
            return await axios.post(url, { name })
        } catch(e) {
            console.error(e)
        }
    }

    static async deleteUsername(id) {
        const endpoint = `/username/${id}`
        const url = this._getURL(endpoint)

        try {
            return await axios.delete(url)
        } catch(e) {
            console.error(e)
        }
    }

    static async getAccounts() {
        const endpoint = '/account'
        const url = this._getURL(endpoint)

        try {
            const resp = await axios.get(url)
            return resp.data.accounts.map((a) => ({
                id: a.id,
                phoneNumber: a.phone_number,
                appId: a.app_id,
                apiHash: a.api_hash,
                waitFor: a.wait_for,
                status: a.status,
                lastUsedAt: a.last_used_at,
                isDirtyAccount: a.is_dirty_account,
                countInterceptedAccounts: a.count_intercepted_accounts,
                waitForV2: a.wait_for_v2,
            }))
        } catch(e) {
            console.error(e)
        }
    }

    static async createAccount(account) {
        const endpoint = '/account'
        const url = this._getURL(endpoint)

        try {
            const resp = await axios.post(url, account)
            return {
                isWaitCode: resp.data.waitForCode,
                accountId: resp.data.accountId
            }
        } catch(e) {
            console.error(e)
        }
    }

    static async deleteAccount(id) {
        const endpoint = `/account/${id}`
        const url = this._getURL(endpoint)

        try {
            return await axios.delete(url)
        } catch(e) {
            console.error(e)
        }
    }

    static async sendCode(id, code) {
        const endpoint = `/account/${id}/code`
        const url = this._getURL(endpoint)

        try {
            const resp = await axios.post(url, { code })
            return resp.data
        } catch(e) {
            console.error(e)
        }
    }

    static async sendPassword(id, password) {
        const endpoint = `/account/${id}/password`
        const url = this._getURL(endpoint)

        try {
            return await axios.post(url, { password })
        } catch(e) {
            console.error(e)
        }
    }

    static async getParams() {
        const endpoint = '/params'
        const url = this._getURL(endpoint)

        try {
            const res = await axios.get(url)
            return res.data.params.map((p) => ({
                typeId: p.type_id,
                value: p.value
            }))
        } catch(e) {
            console.error(e)
        }
    }

    static async saveParams(params) {
        const endpoint = '/params'
        const url = this._getURL(endpoint)

        try {
            return await axios.post(url, { params })
        } catch(e) {
            console.error(e)
        }
    }

    static _getURL(endpoint) {
        return [this.BACKEND_URL, endpoint].join('')
    }
}