import { useContext, useState } from 'react';
import MyButton from '../UI/MyButton/MyButton';
import MyInput from '../UI/MyInput/MyInput';
import slyleClasses from './CreateAccountWindow.module.css'
import API from '../../API/API';
import MyCheckbox from '../UI/MyCheckbox/MyCheckbox.jsx';
import NotificationContext from '../../context/NotificationContext.js';

function CreateAccountWindow({ refreshAccounts, closeWindow }) {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [appId, setAppId] = useState('')
    const [apiHash, setApiHash] = useState('')
    const [isDirtyAccount, setIsDirtyAccount] = useState(false)
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [isWaitForCode, setIsWaitForCode] = useState(false)
    const [isWaitForPassword, setIsWaitForPassword] = useState(false)
    const [accountId, setAccountId] = useState(null)

    const { addNotification } = useContext(NotificationContext);

    function onSendPassword(pass) {
        API.sendPassword(accountId, pass)
            .then((resp) => {
                refreshAccounts(resp)
                setIsWaitForCode(false)
                setIsWaitForPassword(false)
                closeWindow()
            })
            .catch(() => {
                addNotification({
                    text: "Ошибка!",
                    status: 'error',
                  });
            })
    }

    function onSendCode(code) {
        API.sendCode(accountId, code)
            .then((res) => {
                if (res.isWaitForPassword) {
                    setIsWaitForPassword(true)
                } else {
                    setIsWaitForCode(false)
                    setIsWaitForPassword(false)
                    closeWindow()
                }
                refreshAccounts()
            })
            .catch(console.error)
    }

    function onCreateAccount(account) {
        API.createAccount(account)
        .then((res) => {
            if (res.isWaitCode) {
                setAccountId(res.accountId)
                setIsWaitForCode(true)
            }
            refreshAccounts()
        })
        .catch(() => {
            addNotification({
                text: "Ошибка!",
                status: 'error',
              });
        })
    }

    function onSubmit(e) {
        e.preventDefault()
        if (!phoneNumber) {
            window.alert('Номер телефона не может быть пустым')
            return
        }
        if (!appId) {
            window.alert('App ID не может быть пустым')
            return
        }
        if (!apiHash) {
            window.alert('API hash не может быть пустым')
            return
        }
        if (isWaitForPassword) {
            onSendPassword(password)
            return
        }
        if (isWaitForCode) {
            onSendCode(code)
            return
        }
        onCreateAccount({
            phoneNumber,
            appId,
            apiHash,
            isDirtyAccount,
        });
    }

    return (
        <div>
            <form onSubmit={onSubmit} className={slyleClasses.form}>
                <MyInput
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder='Номер телефона'
                    disabled={!!isWaitForCode}
                />
                <MyInput
                    value={appId}
                    onChange={(e) => setAppId(e.target.value)}
                    placeholder='App ID'
                    disabled={!!isWaitForCode}
                />
                <MyInput
                    value={apiHash}
                    onChange={(e) => setApiHash(e.target.value)}
                    placeholder='API hash'
                    disabled={!!isWaitForCode}
                />
                <MyCheckbox
                    isChecked={isDirtyAccount}
                    setIsChecked={setIsDirtyAccount}
                    label='Не сохранять имена на этот аккаунт'
                />
                {
                    isWaitForCode
                    ? <MyInput
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder='Код'
                        disabled={!!isWaitForPassword}
                    />
                    : null
                }
                {
                    isWaitForPassword
                    ? <MyInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Пароль'
                    />
                    : null
                }
                <MyButton>Сохранить</MyButton>
            </form>
        </div>
    );
}

export default CreateAccountWindow;
