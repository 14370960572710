import { Paths } from "../constants/Paths.js";
import { AccountPage } from "../pages/AccountPage.jsx";
import { ErrorPage } from "../pages/ErrorPage.jsx";
import { HomePage } from "../pages/HomePage.jsx";
import { SettingsPage } from "../pages/SettingsPage.jsx";

export const routes = [
    {
        path: Paths.HOME_PAGE,
        exact: true,
        component: HomePage
    },
    {
        path: Paths.ACCOUNT,
        exact: true,
        component: AccountPage
    },
    {
        path: Paths.SETTINGS_PAGE,
        exact: true,
        component: SettingsPage
    },
    {
        path: Paths.ERROR,
        exact: true,
        component: ErrorPage
    },
]